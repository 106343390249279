import React, {Component} from 'react';
import {Image, Rect} from 'react-konva';
import queryString from 'query-string';

const params = queryString.parse(window.location.search);

class URLImage extends Component {

    state = {
        image: null
    };

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        if (this.image) {
            this.image.removeEventListener('load', this.handleLoad.bind(this));
        }
    }

    loadImage() {
        if (this.props.src) {
            if (!(parseInt(params.robot) === 1 && this.props?.multiface_elements_admin_only === 1)) {
                this.props.setItemDownloadingStatus({
                    isItemDownloading: 1
                });
            }

            let imageUrl = parseInt(params.robot) ? this.props.src : (this.props.smallBackground_image && this.props.smallBackground_image.length > 0 ? this.props.smallBackground_image : this.props.src);

            if (parseInt(params.robot) && imageUrl) {
                // console.log(imageUrl, "onloaded")
                imageUrl = imageUrl.replace('/resized', '');
                imageUrl = imageUrl.replace('/thumbs', '');
                // console.log(imageUrl, "changed")
            }

            this.image = new window.Image();
            this.image.src = imageUrl;

            this.image.src = imageUrl;
            if (this.props.type !== 'icon') {
                this.image.crossOrigin = "anonymous";
            }
            this.image.addEventListener('load', this.handleLoad.bind(this));
            this.image.addEventListener("error", () => {

                this.props.setImgStatusError({
                    noImgLoadError: false
                });

                if (this.props.type === 'icon') {
                    console.log("Icon load error");
                } else if (this.props.name === 'Background') {
                    console.log("Background image load error");
                } else {
                    console.log("Image load error");
                }

                window.parent.postMessage({eventType: "HIDE_LOADING"}, "*");

                if (!(parseInt(params.robot) === 1 && this.props?.multiface_elements_admin_only === 1)) {
                    this.props.setItemDownloadingStatus({
                        isItemDownloading: -1
                    });

                }
            });
        }
    }

    handleLoad() {
        if (this.props.uploadedImages && this.props.uploadedImages.length > 0) {
            if (parseInt(params.robot) && this.props?.multiface_elements_admin_only !== 1) {

                let originalWidth;
                let originalHeight;
                let resizedWidth;

                this.props.uploadedImages.forEach((item2) => {

                    if (item2.url.replace('/thumbs', '') === this.image.src.replace('/resized', '')) {
                        // console.log(item2)

                        originalWidth = item2.filesDimensionsOriginal.width
                        originalHeight = item2.filesDimensionsOriginal.height
                        resizedWidth = item2.filesDimensions.width;

                        console.log("originalWidth ", originalWidth, " (only admin can see it),");
                        console.log("originalHeight", originalHeight, " (only admin can see it),");
                        console.log("this.props.cropWidth", this.props.cropWidth, " (only admin can see it),")
                        console.log("this.props.cropHeight", this.props.cropHeight, " (only admin can see it).")
                    }
                });

                let ratio = originalWidth / resizedWidth;


                if (!isNaN(ratio) && (originalWidth !== this.props.cropWidth && originalHeight !== this.props.cropHeight)) {
                    let {
                        cropX = 0,
                        cropY = 0,
                        cropWidth = 0,
                        cropHeight = 0,
                        newBoxX = 0,
                        newBoxY = 0,
                        newBoxWidth = 0,
                        newBoxHeight = 0
                    } = this.props

                    this.props.onEditElement({
                        imageCrop: {
                            x: Math.round(cropX * ratio),
                            y: Math.round(cropY * ratio),
                            width: Math.round(cropWidth * ratio) > originalWidth ? originalWidth : Math.round(cropWidth * ratio),
                            height: Math.round(cropHeight * ratio) > originalHeight ? originalHeight : Math.round(cropHeight * ratio),
                            newBoxX: Math.round(newBoxX * ratio),
                            newBoxY: Math.round(newBoxY * ratio),
                            newBoxWidth: Math.round(newBoxWidth * ratio),
                            newBoxHeight: Math.round(newBoxHeight * ratio),
                        },
                        historyMode: false,
                        element: this.props.name,
                        activePage: this.props.activePage,
                    });
                }
            }
        }


        if (!(parseInt(params.robot) === 1 && this.props?.multiface_elements_admin_only === 1)) {
            this.props.setItemDownloadingStatus({
                isItemDownloading: -1
            });
        }

        window.parent.postMessage({eventType: "HIDE_LOADING"}, "*");
        // console.log(this.image)
        this.setState({
            image: this.image
        });
    };

    render() {
        let _this = this;

        function offset(value = 10) {
            if (!_this.props.background) return Math.round((value / 2));
            return 0;
        }

        if (this.props.elementRepeat || this.props.elementRepeatOval) {
            return <Rect
                perfectDrawEnabled={true}
                naturalWidth={this.props.naturalWidth}
                imageWithoutBg={this.props.imageWithoutBg}
                faceCropped={this.props.faceCropped}
                naturalHeight={this.props.naturalHeight}
                fillPatternScale={{
                    x: this.props?.width / (this.image?.width > 0 ? this.image?.width : 1),
                    y: this.props?.height / (this.image?.height > 0 ? this.image?.height : 1)
                }}
                cornerRadius={300}
                keepRatio={this.props.keepRatio}
                fillPatternRepeat={'no-repeat'}
                fillPatternImage={this.state.image}
                cropWidth={this.props.cropWidth}
                cropHeight={this.props.cropHeight}
                cropX={this.props.cropX}
                cropY={this.props.cropY}
                newBoxX={this.props.newBoxX}
                newBoxY={this.props.newBoxY}
                newBoxWidth={this.props.newBoxWidth}
                newBoxHeight={this.props.newBoxHeight}
                heightDiff={this.props.heightDiff}
                widthDiff={this.props.widthDiff}
                type={this.props.type}
                name={this.props.name}
                x={this.props.x}
                y={this.props.y}
                width={this.props.width}
                height={this.props.height}
                image={this.state.image}
                draggable={((this.props.mode === 'ADMIN' && !this.props.background) ? true : this.props.draggable)}
                onDragEnd={this.props.onDragEnd}
                onTransformEnd={this.props.onTransformEnd}
                rotation={this.props.rotation}
                scaleX={this.props.scaleX}
                scaleY={this.props.scaleY}
                admin={this.props.admin}
                offsetX={offset(this.props?.width)}
                offsetY={offset(this.props?.height)}
                changeable={this.props.changeable}
                user_changeable={this.props.user_changeable}
                quickEdit={this.props.quickEdit}
                user_quickEdit={this.props.user_quickEdit}
                user_removeFromPreview={this.props.user_removeFromPreview}
                user_removeFromPrint={this.props.user_removeFromPrint}
                user_adminAccessOnly={this.props.user_adminAccessOnly ?? false}
                unselectable={this.props.unselectable}
                user_foreground={this.props.user_foreground}
                user_isShapeHeart={this.props.user_isShapeHeart}
                deletable={this.props.deletable}
                transformable={this.props.transformable}
                user_transformable={this.props.user_transformable}
                isGlobalElementUrl={this.props.isGlobalElementUrl ?? false}
                quick_edit_labels={this.props.quick_edit_labels}
                elementRepeat={this.props.elementRepeat}
                elementRepeatOval={this.props.elementRepeatOval}
                faceId={this.props.faceId}
                sceneFunc={this.props.user_isShapeHeart ? (context, shape) => {

                    let
                        w = this.props?.width,
                        h = this.props?.height;

                    let d = Math.min(w, h);
                    let k = 0;
                    context.beginPath();
                    context.moveTo(k, k + d / 4);
                    context.quadraticCurveTo(k, k, k + d / 4, k);
                    context.quadraticCurveTo(k + d / 2, k, k + d / 2, k + d / 4);
                    context.quadraticCurveTo(k + d / 2, k, k + d * 3 / 4, k);
                    context.quadraticCurveTo(k + d, k, k + d, k + d / 4);
                    context.quadraticCurveTo(k + d, k + d / 2, k + d * 3 / 4, k + d * 3 / 4);
                    context.lineTo(k + d / 2, k + d);
                    context.lineTo(k + d / 4, k + d * 3 / 4);
                    context.quadraticCurveTo(k, k + d / 2, k, k + d / 4);
                    context.closePath();
                    // context.fillStyle = context.createPattern(this.state.image, 'no-repeat');
                    // context.fill();
                    context.fillStrokeShape(shape);
                } : null
                }
            />
        }
        return (
            <Image
                fill={this.props.bgColor ? this.props.bgColor : ""}
                naturalWidth={this.props.naturalWidth}
                naturalHeight={this.props.naturalHeight}
                faceId={this.props.faceId}
                keepRatio={this.props.keepRatio}
                cropWidth={this.props.cropWidth}
                cropHeight={this.props.cropHeight}
                cropX={this.props.cropX}
                cropY={this.props.cropY}
                newBoxX={this.props.newBoxX}
                newBoxY={this.props.newBoxY}
                newBoxWidth={this.props.newBoxWidth}
                newBoxHeight={this.props.newBoxHeight}
                heightDiff={this.props.heightDiff}
                widthDiff={this.props.widthDiff}
                type={this.props.type}
                name={this.props.name}
                x={this.props.x}
                y={this.props.y}
                width={this.props.width}
                height={this.props.height}
                image={this.props.bgColor ? '' : this.state.image}
                draggable={((this.props.mode === 'ADMIN' && !this.props.background) ? true : this.props.draggable)}
                onDragEnd={this.props.onDragEnd}
                onTransformEnd={this.props.onTransformEnd}
                rotation={this.props.rotation}
                scaleX={this.props.scaleX}
                scaleY={this.props.scaleY}
                admin={this.props.admin}
                offsetX={offset(this.props.width)}
                offsetY={offset(this.props.height)}
                changeable={this.props.changeable}
                user_changeable={this.props.user_changeable}
                isCutoutPro={this.props.isCutoutPro}
                isCutoutProBg={this.props.isCutoutProBg}
                user_removeFromWorkspace={this.props.user_removeFromWorkspace}
                layer_allocation_data={this.props.layer_allocation_data}
                cartoonTypeValue={this.props.cartoonTypeValue}
                quickEdit={this.props.quickEdit}
                user_quickEdit={this.props.user_quickEdit}
                user_removeFromPreview={this.props.user_removeFromPreview}
                user_removeFromPrint={this.props.user_removeFromPrint}
                user_adminAccessOnly={this.props.user_adminAccessOnly ?? false}
                transformable={this.props.transformable}
                user_transformable={this.props.user_transformable}
                isGlobalElementUrl={this.props.isGlobalElementUrl ?? false}
                quick_edit_labels={this.props.quick_edit_labels}
                elementRepeat={this.props.elementRepeat}
                elementRepeatOval={this.props.elementRepeatOval}
                unselectable={this.props.unselectable}
                user_foreground={this.props.user_foreground}
                user_isShapeHeart={this.props.user_isShapeHeart ?? false}
                deletable={this.props.deletable}
                faceCropped={this.props.faceCropped}

            />
        );
    }
}

export default URLImage;
