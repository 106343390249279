import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import stl from "./FaceCropPopup.module.scss"


import close_btn from '../../assets/images/iconfinder_Close_6351931 2.png'
import back_btn from '../../assets/images/expand-more.png'
import Translate from "react-translate-component";
import { post } from "axios";
import {
    DESKTOP_WIDTH, MAX_IMAGE_SCALE, PREVIEW_SIZE,
} from "../../config";

import { Button } from "shards-react";
import loadingImage from "../../assets/images/loading.gif";
import clsx from "clsx";
import queryString from "query-string";
import ProductPreview from "../ProductPreview/ProductPreview";
import TranslateForAlert from "../../helpes/translateForAlert";

import fireicon from '../../assets/images/fireicon.png'
import clapicon from '../../assets/images/clapicon.png'

import farbe_anpassen from '../../assets/images/farbe-anpassen.png'
import text_anpassen from '../../assets/images/text-anpassen.png'
import elemente_anpassen from '../../assets/images/elemente-anpassen.png'

import { getDesignerMode } from '../../store/actions/globals';


const params = queryString.parse(window.location.search);

class ImageCropPopup extends Component {
    state = {
        imgSource: "",
        border: 50,
        scale: 1.2,
        rotate: 0,
        elementScaling: false,
        touchEventAdded: false,
        borderRadius: 0,
        preview: { img: undefined },
        isNewImgDownloaded: false,
        loading: false,
        loadingFailed: false,
        loadingSuccess: false,
        showLoading: false,
        previewMode: false,
        previewChecked: false,
        geniusTextLang: "",
    };

    sliderStyles = {
        track: {
            backgroundColor: "#A7AAA8",
            height: 1,
            width: 200,
        },
        active: {
            backgroundColor: '#222C28'
        },
        thumb: {
            width: 21,
            height: 21
        },
        disabled: {
            opacity: 0.5
        }
    }

    editor = React.createRef();
    editorWrap = React.createRef();

    getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentDidMount() {
        this.generatePreview();
    }

    saveDesigner = () => {
        
        let allImagesHasGoodQuality = true;
        this.props.pages[this.props.activePage].canvasElements.forEach((item) => {
            if (item?.type === 'image') {

                const { width, height, naturalWidth, naturalHeight } = item;
                if (width === undefined || height === undefined || naturalWidth === undefined || naturalHeight === undefined) return
                if (width / naturalWidth > MAX_IMAGE_SCALE || height / naturalHeight > MAX_IMAGE_SCALE) {
                    TranslateForAlert("Your image is too small")
                    console.warn(item)
                    allImagesHasGoodQuality = false;
                }
            }
        });

        if (allImagesHasGoodQuality) {
            if (this.props.mode !== 'PRODUCTION') {
                let layout = this.props.pages[this.props.activePage],
                    {
                        x = layout?.borderLimit?.x,
                        y = layout?.borderLimit?.y,
                        width = layout?.borderLimit?.width * (layout?.borderLimit?.scaleX ?? 1),
                        height = layout?.borderLimit?.height * (layout?.borderLimit?.scaleY ?? 1),
                    } = this.props?.isBorderLimitEditing;

                let stageCopy = this.props.stage.clone();
                stageCopy.getChildren()[0].children.forEach((child) => {
                    if (child?.attrs?.user_removeFromPreview !== undefined && child.attrs.user_removeFromPreview) {
                        // stageCopy.find("." + child.attrs.name).remove();
                        stageCopy.find("." + child.attrs.name).destroy();
                    }
                });
                this.props.onSaveLayer({
                    newTemplateRatio: {
                        widthRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio ?? 1,
                        heightRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.heightRatio ?? 1
                    },
                    borderLimit: { x, y, width, height, status: false },
                    stage: stageCopy,
                    magentoUrl: this.props.magentoUrl,
                    designId: this.props.designId,
                    statusText: 'Saving...',
                    mode: this.props.mode,
                    sessionId: this.props.sessionId,
                    productId: this.props.productId,
                    layerId: this.props.pages[this.props.activePage]?.baseLayer_id || this.props.layerId,
                    toolTextAllowed: this.props.toolTextAllowed,
                    toolEmojiAllowed: this.props.toolEmojiAllowed,
                    toolShapesAllowed: this.props.toolShapesAllowed,
                    toolImagesAllowed: this.props.toolImagesAllowed,
                    propertyTextStrokeAllowed: this.props.propertyTextStrokeAllowed,
                    propertyTextStrokeColorAllowed: this.props.propertyTextStrokeColorAllowed,
                    propertyTextShadowColorAllowed: this.props.propertyTextShadowColorAllowed,
                    propertyTextShadowAllowed: this.props.propertyTextShadowAllowed,
                    propertyTextColorAllowed: this.props.propertyTextColorAllowed,
                    propertyTextShowAllowed:this.props.propertyTextShowAllowed,
                    propertyTextFontAllowed: this.props.propertyTextFontAllowed,
                    propertyFaceCropSizeAllowed: this.props.propertyFaceCropSizeAllowed,
                    propertyFaceCropZoomAllowed: this.props.propertyFaceCropZoomAllowed,
                    propertyFaceCropRotateAllowed: this.props.propertyFaceCropRotateAllowed,
                });

            } else {
                if (queryString.parse(window.location.search).admin_edit !== undefined) {
                    /* Generate filename from data */
                    let filename = queryString.parse(window.location.search).filename;
                    let stageCopy = this.props.stage.clone();

                    /* Get print image size */
                    let templateInfo = this.props.pages[this.props.activePage];

                    /* Calculate image ratios */
                    let templateWidth = parseInt(templateInfo.width);
                    let stageWidth = parseInt(stageCopy.attrs.width);
                    let pixelRatio = parseFloat(templateWidth / stageWidth);
                    let previewRatio = parseFloat(PREVIEW_SIZE / stageWidth);

                    /* Remove transformer from preview file */
                    stageCopy.removeName('Transformer');
                    let needToDelete = [];
                    stageCopy.getChildren()[0].children.forEach(function (child) {
                        if (child.attrs.user_removeFromPreview !== undefined && child.attrs?.user_removeFromPreview) {
                            needToDelete.push(child);
                        }
                    });
                    // needToDelete.forEach(child => child.remove())
                    needToDelete.forEach(child => child.destroy())
                    let previewImage = stageCopy.toDataURL({ pixelRatio: previewRatio });

                    /* Remove transformer from final print file */
                    let printCopy = this.props.stage.clone();

                    needToDelete = [];
                    printCopy.getChildren()[0].children.forEach(function (child) {
                        if (child.constructor.name === 'Transformer') needToDelete.push(child);
                        if (child.attrs.user_removeFromPrint !== undefined) {
                            if (child.attrs?.user_removeFromPrint) {
                                needToDelete.push(child);
                            }
                        }
                    });
                    // needToDelete.forEach(child => child.remove())
                    needToDelete.forEach(child => child.destroy())

                    /* Prepare files for save request */
                    let formData = new FormData();
                    let config = { headers: { 'content-type': 'multipart/form-data' } };
                    formData.append('filename', filename);
                    formData.append('print_image', '');
                    formData.append('preview_image', previewImage);

                    if (parseInt(params.robot)) {
                        var self = this;

                        post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(() => {
                            self.setState({ buttonSuffix: " - " + self.state.curTime });
                        }).catch(function (error) {
                            console.log(error);
                        });
                    } else {
                        let ifNeedSave = window.confirm("Are you sure you want to change the design in the order?");

                        /* Send files save request */
                        if (ifNeedSave) {
                            post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(() => {
                                // handle success
                                alert("Design edited");
                            }).catch(function (error) {
                                // handle error
                                console.log(error);
                            });
                        }
                    }
                } else {
                    let quickEditElements = {};
                    let stageChildren = this.props.stage.children;
                    let stageElements = Object.values(stageChildren)[0];
                    let stageElementsChildren = stageElements.children;
                    Object.keys(stageElementsChildren).forEach(function (key) {
                        let elem = stageElementsChildren[key];
                        // console.log(elem);
                        if (elem.attrs !== undefined) {
                            if (elem.attrs.quickEdit) {
                                if (elem.attrs.type === 'image') {
                                    quickEditElements[elem?.attrs?.name] = {
                                        name: elem?.attrs?.name,
                                        type: elem?.attrs?.type,
                                        img: elem?.attrs?.image?.src,
                                        fill: elem?.attrs?.fill
                                    }
                                } else if (elem.attrs.type === 'text') {
                                    quickEditElements[elem?.attrs?.name] = {
                                        name: elem?.attrs?.name,
                                        type: elem?.attrs?.type,
                                        text: elem?.attrs?.text,
                                        fill: elem?.attrs?.fill
                                    }
                                } else if (elem.attrs.type === 'shape') {
                                    // For future :-)
                                }
                            }
                        }
                    });

                    window.parent.postMessage({
                        datatype: "saveimage",
                        canvasElements: JSON.stringify({ canvasElements: quickEditElements })
                    }, "*");
                }
            }
        }
        window.close();
    }

    generatePreview() {

        if (this.props.stage) {
            if (this.props.isItemDownloading > 0) {
                setTimeout(() => {
                    this.generatePreview()
                }, 1000)
            } else {
                let stage = this.props.stage.clone();
                this.props.generatePreview({
                    pages: this.props.pages,
                    activePage: this.props.activePage,
                    stage: stage,
                    previewUrl: this.props.previewUrl
                });
                this.props.setPreviewUpdateStatus({ previewShouldBeChanged: false })
            }
        }
    }

    getGeniusTextLang() {
        let langText, lang = queryString.parse(window.location.search).lang;
        switch (lang) {
            case "de":
                langText = `Gratuliere! <span><img src=${fireicon}> Ein Meisterwerk ...</span>`
                break;
            case "fr":
                langText = `Bravo! <span><img src=${clapicon}> Vous avez fait du bon boulot!</span>`
                break;
            case "it":
                langText = `Congratulazioni! <span><img src=${fireicon}> Un capolavoro!</span>`
                break;
            case "es":
                langText = `¡Enhorabuena! <span><img src=${fireicon}> ¡Una obra maestra!</span>`
                break;
            case "nl":
                langText = `Gefeliciteerd! <span><img src=${fireicon}> Het is een meesterwerk!</span>`
                break;
            case "dk":
                langText = `Tillykke! <span><img src=${fireicon}> Et mesterværk!</span>`
                break;
            case "en":
                if (params.magento_url !== undefined && params.magento_url.includes('firebox')) {
                    langText = `This looks amazing! <span>You\`re a creative genius <img src=${fireicon}></span>`
                } else {
                    langText = `This looks amazing! <span><img src=${fireicon}> You\`re a creative genius ...</span>`
                }
                break;
            default:
                langText = `This looks amazing! <span><img src=${fireicon}> You\`re a creative genius ...</span>`
                break;
        }
        return langText;
    }

    render() {
        // console.log('thisPropsOrderPreview', this.props);
        //console.log(this.props.pages[this.props.activePage].multiface_elements_admin_only,'=-=-=-=-=->?>??>>>',this.props);

        let shouldTextPopupOpen = false;
        this.props.pages[0].canvasElements.forEach((i) => {
            if (i.type === "text" && i.changeable) {
                shouldTextPopupOpen = true
            }
        });

        let activePage = this.props.pages[this.props.activePage];
        let colors = [];
        for (let key in activePage.backgrounds) {
            let item = activePage.backgrounds[key]
            if (activePage.backgrounds[key].type === 'color') {
                colors.push(item.color_code);
            }
        }
        let backgrounds = [];
        let pageBackgrounds = activePage.designs;
        for (let key in pageBackgrounds) {
            if (!pageBackgrounds.hasOwnProperty(key)) continue;
            let bck = pageBackgrounds[key];
            backgrounds.push({ key: key, background_image: bck.image, smallBackground_image: bck.small_image });
        }

        const { showLoading } = this.state;
        const { previews: previewsProps } = this.props;
        let previews = previewsProps.map((item) => {
            return (                                        
                <img key={item.name} src={item.image} alt={''} />
            )
        });

        this.state.geniusTextLang = this.getGeniusTextLang();
        console.log(this.props,"props are")

        return (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay"
                    onClick={this.props.hidePopup} />
                <div
                    className={`text-form-popup-content-inner ${showLoading ? 'faceCropLoading' : ''} faceCropMultiface faceCropMultifaceWrap orderPreviewPopup`}
                // className={`text-form-popup-content-inner ${showLoading ? 'faceCropLoading' : ''}`}
                >
                    <div className="faceCropMultifaceTitle"></div>

                    <div className="text-form-popup-content-title__wrap">
                        <div className="text-form-popup-back"
                            onClick={() => {

                                this.props.hidePopup()

                                if (this.props.pages[this.props.activePage].multiface_elements_admin_only === 1) {
                                    this.props.setPreviewUpdateStatus({ previewShouldBeChanged: true })
                                    //this.props.showFaceCropPopup()
                                    let IsTextPopup = false;
                                    this.props.pages[0].canvasElements.forEach((i) => {
                                        if (i.type === "text" && i.changeable) {
                                            IsTextPopup = true
                                        }
                                    });
                                    if (colors.length > 0) {
                                        this.props.hidePopup()
                                        this.props.showBackgroundChangePopup({ displaytype: 'color' })
                                    } else if (backgrounds.length > 0) {
                                        this.props.hidePopup()
                                        this.props.showBackgroundChangePopup({ displaytype: 'design' })
                                    } else if (IsTextPopup) {
                                        this.props.onShowTextForm();
                                        this.props.showTextChangePopup();
                                        //this.props.showBackgroundChangePopup({ displaytype: 'shop' })
                                    } else {
                                        this.props.showFaceCropPopup();
                                        //this.props.showBackgroundChangePopup({ displaytype: 'onlyprv' })
                                    }
                                }
                                this.setState({ previewChecked: false })
                            }}>
                            {
                                this.state.loading ? null : <img src={back_btn} alt="" />
                            }
                        </div>

                        <div className="text-form-popup-content-title"><Translate content={"Preview"} /></div>
                        <div className="text-form-popup-close"
                            onClick={() => {
                                if (this.props.pages[this.props.activePage].multiface_elements_admin_only === 1) {
                                    window.parent.postMessage({ datatype: "hide_designer" }, "*")
                                    this.props.setPreviewUpdateStatus({ previewShouldBeChanged: true })
                                    window.onbeforeunload = null;
                                } else {
                                    this.props.hidePopup()
                                }
                                this.setState({ previewChecked: false })
                            }}>
                            <img src={close_btn} alt="" />
                        </div>
                    </div>

                    <div className="orderPreviewPopup__wrap">

                        <div className="faceCropMultifacePreview">
                            <span className={"faceCropMultifacePreview__status"}>
                                {this.props?.pages[this.props.activePage]?.renderers?.length > 0 ? (previews?.length > 0 && !this.props.previewShouldBeChanged ? previews :
                                    <Translate content={"Loading"} />) : "preview not fount"}
                            </span>

                            <div dangerouslySetInnerHTML={{ __html: this.state.geniusTextLang }} className="firebox-genius-heading firebox-genius-heading-mobile"></div>
                            {this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ?
                                <>
                                    {/* {this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 && previews?.length > 0 && !this.props.previewShouldBeChanged ?
                                        <Button theme="secondary" outline onClick={() => {
                                            this.props.hidePopup()
                                            this.props.showFaceCropPopup()
                                            this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                        }}>
                                            <Translate content={"edit Image"}/>
                                        </Button> :  <Button theme="secondary" outline onClick={() => {
                                            this.props.hidePopup()
                                            this.props.showFaceCropPopup()
                                            this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                        }}>
                                            <Translate content={"edit Image"}/>
                                        </Button>  //null bya 1031
                                    } */}
                                    {/* {this.props.displaytype == 'shop' ?  */}
                                    {this.props.pages[this.props.activePage].multiface_elements_admin_only == 0 ?
                                        <Button theme="secondary" outline onClick={() => {
                                            this.props.hidePopup()
                                            //this.props.showImageChangePopup()
                                            //this.props.showFaceCropPopup()
                                            this.props.setPreviewUpdateStatus({ previewShouldBeChanged: true })
                                        }}>
                                            <Translate content={"edit Image"} />
                                        </Button>
                                        :
                                        <>
                                            <Button theme="secondary" outline onClick={() => {
                                                this.props.hidePopup()
                                                //this.props.showImageChangePopup()
                                                this.props.showFaceCropPopup()
                                                this.props.setPreviewUpdateStatus({ previewShouldBeChanged: true })
                                            }}>
                                                <Translate content={"Change Image"} />
                                            </Button>
                                            {shouldTextPopupOpen ?
                                                <Button theme="secondary" outline onClick={() => {
                                                    this.props.hidePopup()
                                                    this.props.onShowTextForm();
                                                    this.props.showTextChangePopup();
                                                    this.props.setPreviewUpdateStatus({ previewShouldBeChanged: true })
                                                }}>
                                                    <Translate content={"texts_lang"} />
                                                </Button>
                                                : ""
                                            }
                                            {backgrounds.length > 0 ?
                                                <Button theme="secondary" outline onClick={() => {
                                                    this.props.hidePopup()
                                                    this.props.showBackgroundChangePopup({ displaytype: 'design' })
                                                    this.props.setPreviewUpdateStatus({ previewShouldBeChanged: true })
                                                }}>
                                                    <Translate content={"Design_anpassen_lang"} />
                                                </Button>
                                                : ""
                                            }
                                            {colors.length > 0 ?
                                                <Button theme="secondary" outline onClick={() => {
                                                    this.props.hidePopup()
                                                    this.props.showBackgroundChangePopup({ displaytype: 'color' })
                                                    this.props.setPreviewUpdateStatus({ previewShouldBeChanged: true })
                                                }}>
                                                    <Translate content={"Hintergundfarbe_anpassen_lang"} />
                                                </Button>
                                                : ""
                                            }
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <div className={'previewCommon-wrapper'}>
                                    {backgrounds.length > 0 ?
                                            <Button theme="secondary" outline onClick={() => {
                                                this.props.CommonEditorStep({ stepname: 'bgdesign' });
                                                this.props.hidePopup()
                                                if (this.props.showDesignForm) {
                                                    this.props.hideToolsMenu()
                                                } else {
                                                    this.props.onShowDesignsForm()
                                                }
                                                this.setState({
                                                    toolsMoreShow: false
                                                });
                                            }}>
                                                <img src={farbe_anpassen} alt="" />
                                                <Translate content={"Design_anpassen_lang"} />
                                            </Button>
                                            :
                                            ""
                                        }
                                        {colors.length > 0 ?
                                            <Button theme="secondary" outline onClick={() => {
                                                this.props.CommonEditorStep({ stepname: 'bgcolor' });
                                                this.props.hidePopup()
                                                if (this.props.showBackgroundsForm) {
                                                    this.props.hideToolsMenu()
                                                } else {
                                                    this.props.onShowBackgroundsForm()
                                                }
                                                this.setState({
                                                    toolsMoreShow: false
                                                });
                                            }}>
                                                <img src={farbe_anpassen} alt="" />
                                                <Translate content={"Hintergundfarbe_anpassen_lang"} />
                                            </Button>
                                            :
                                            ""
                                        }
                                        {shouldTextPopupOpen ?
                                            <Button theme="secondary" outline onClick={() => {
                                                this.props.hidePopup()
                                                this.props.CommonEditorStep({ stepname: 'textspopupCommon' });
                                                setTimeout(() => {
                                                    this.props.onShowTextForm()
                                                    //this.props.hideToolsMenu()
                                                    this.setState({
                                                        toolsMoreShow: false
                                                    });
                                                    setTimeout(() => {
                                                        if (document.getElementById("elementtxtid_0")) {
                                                            const textareaFirst = document.getElementById("elementtxtid_0");
                                                            const endMove = textareaFirst.value.length;
                                                            textareaFirst.setSelectionRange(endMove, endMove);
                                                            textareaFirst.focus();
                                                        }
                                                    }, 100);
                                                }, 100);
                                            }}>
                                                <img src={text_anpassen} alt="" />
                                                {/* <Translate content={"Edit Text"} /> */}
                                                <Translate content={"texts_lang"} />
                                            </Button>
                                            :
                                            ""
                                        }
                                        {((!this.props.isCustomMaskEditing?.status && (this.props.toolImagesAllowed)) || (this.props.toolEmojiAllowed && !this.props.isCustomMaskEditing?.status)) ?
                                            <Button className={'commonElementeBtn'} theme="secondary" outline onClick={() => {
                                                this.props.hidePopup()
                                                this.props.CommonEditorStep({ stepname: 'mehrCommon' });
                                                setTimeout(() => {
                                                    this.props.hideToolsMenu()
                                                    this.setState({
                                                        toolsMoreShow: true
                                                    });
                                                }, 100);
                                            }}>
                                                <img src={elemente_anpassen} alt="" />
                                                {/* <Translate content={"More"}/> */}
                                                <Translate content={"Mehr_anpassen_lang"} />
                                            </Button>
                                            :
                                            ""
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        <div className="orderPreviewPopup__checkboxWrap">
                            <div dangerouslySetInnerHTML={{ __html: this.state.geniusTextLang }} className="firebox-genius-heading firebox-genius-heading-desktop"></div>
                            <div className="config-p review-checkbox cs-review-checkbox">
                                <input type="checkbox"
                                    name="config_preview_check"
                                    value={this.state.previewChecked}
                                    id="config_preview_check_8409"
                                    className="config_preview_check_8409"
                                    onClick={() => {
                                        this.setState({
                                            previewChecked: !this.state.previewChecked,
                                            previewCheckMark: !this.state.previewChecked,
                                        })
                                    }}
                                />
                                <label
                                    // onClick={() => {
                                    //     this.setState({
                                    //         previewChecked: !this.state.previewChecked,
                                    //         previewCheckMark: !this.state.previewChecked,
                                    //     })
                                    // }}
                                    className={`conf-preview-check-label`}
                                    style={{ color: this.state.previewCheckMark && !this.state.previewChecked ? "red" : "#333333" }}
                                    htmlFor="config_preview_check_8409">

                                    {params.magento_url !== undefined && params.magento_url.includes('firebox') ?
                                        <span>
                                            {
                                                   params.lang == "es"  ?
                                                    <Translate content="Information before ordering" />
                                                    :
                                                    <span className='firebox-label-cls'>Please double check everything is correct before adding to cart.</span>

                                            }
                                        </span>
                                        :
                                        <Translate content="Information before ordering" />
                                    }
                                </label>
                                <Button onClick={() => {

                                    // console.log("this.props.pages[this.props.activePage].canvasElements", this.props.pages[this.props.activePage].canvasElements)
                                    let allImagesHasGoodQuality = true;
                                    this.props.pages[this.props.activePage].canvasElements.forEach((item) => {
                                        if (item?.type === 'image') {
                                            const { width, height, naturalWidth, naturalHeight } = item;
                                            if (width === undefined || height === undefined || naturalWidth === undefined || naturalHeight === undefined) return
                                            if (width / naturalWidth > MAX_IMAGE_SCALE || height / naturalHeight > MAX_IMAGE_SCALE) {
                                                TranslateForAlert("Your image is too small")
                                                console.warn(item)
                                                allImagesHasGoodQuality = false;
                                            }
                                        }
                                    });

                                    if (allImagesHasGoodQuality) {
                                        if (this.state.previewChecked) {
                                            let filename = this.props.sessionId + "_" + this.props.layerId + "_" + this.props.productId + "_" + new Date().getTime() + ".jpg";
                                            let stageCopy = this.props.stage.clone();

                                            /* Get print image size */
                                            let templateInfo = this.props.pages[this.props.activePage];

                                            /* Calculate image ratios */
                                            let templateWidth = parseInt(templateInfo.width);
                                            let stageWidth = parseInt(stageCopy.attrs.width);
                                            let pixelRatio = parseFloat(templateWidth / stageWidth);
                                            let previewRatio = parseFloat(PREVIEW_SIZE / stageWidth);

                                            /* Remove transformer from preview file */
                                            stageCopy.removeName('Transformer');
                                            let needToDelete = [];
                                            stageCopy.getChildren()[0].children.forEach(function (child) {
                                                if (child.attrs.user_removeFromPreview !== undefined && child.attrs?.user_removeFromPreview) {
                                                    needToDelete.push(child);
                                                }
                                            });
                                            // needToDelete.forEach(child => child.remove())
                                            needToDelete.forEach(child => child.destroy())
                                            let previewImage = stageCopy.toDataURL({ pixelRatio: previewRatio });

                                            /* Remove transformer from final print file */
                                            let printCopy = this.props.stage.clone();

                                            needToDelete = [];
                                            printCopy.getChildren()[0].children.forEach(function (child) {
                                                if (child.constructor.name === 'Transformer') needToDelete.push(child);
                                                if (child.attrs.user_removeFromPrint !== undefined) {
                                                    if (child.attrs?.user_removeFromPrint) {
                                                        needToDelete.push(child);
                                                    }
                                                }
                                            });
                                            // needToDelete.forEach(child => child.remove())
                                            needToDelete.forEach(child => child.destroy())


                                            /* Prepare files for save request */
                                            let formData = new FormData();
                                            let config = { headers: { 'content-type': 'multipart/form-data' } };
                                            formData.append('filename', filename);
                                            formData.append('print_image', '');
                                            formData.append('preview_image', previewImage);

                                            /* Send files save request */

                                            post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(() => {
                                            }).catch(function (error) {
                                                // handle error
                                                console.log(error);
                                            });

                                            //
                                            let layout = this.props.pages[this.props.activePage],
                                                {
                                                    x = layout?.borderLimit?.x,
                                                    y = layout?.borderLimit?.y,
                                                    width = layout?.borderLimit?.width,
                                                    height = layout?.borderLimit?.height,
                                                } = this.props?.isBorderLimitEditing;


                                            printCopy = this.props.stage.clone();
                                            /* Remove transformer from final print file */
                                            needToDelete = [];

                                            printCopy.getChildren()[0].children.forEach(function (child) {
                                                if (child.constructor.name === 'Transformer') needToDelete.push(child);
                                                if (child.attrs.user_removeFromPrint !== undefined) {
                                                    if (child.attrs?.user_removeFromPrint) {
                                                        needToDelete.push(child);
                                                    }
                                                }
                                            });

                                            // needToDelete.forEach(child => child.remove());
                                            needToDelete.forEach(child => child.destroy());

                                            const params = queryString.parse(window.location.search);

                                            let commonProperties = function (element, newTemplateRatio = {
                                                widthRatio: 1,
                                                heightRatio: 1
                                            }, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, isRobot) {


                                                let scaleX = (element.scale_x ?? element.scaleX ?? 1);
                                                let scaleY = (element.scale_y ?? element.scaleY ?? 1);

                                                if ((element.background || element.name === 'Background2') && isRobot) {
                                                    scaleX = scaleX * (newTemplateRatio?.widthRatio ?? 1)
                                                    scaleY = scaleY * (newTemplateRatio?.heightRatio ?? 1)
                                                } else if ((element.background || element.name === 'Background2') && !isRobot) {
                                                    scaleX = scaleX / (newTemplateRatio?.widthRatio ?? 1)
                                                    scaleY = scaleY / (newTemplateRatio?.heightRatio ?? 1)
                                                }

                                                console.log("x save", (element.background || element.name === 'Background2' || isRobot) ? element.x : element.x + (newTemplateRatio.x ?? 0))
                                                return {
                                                    type: element.type,
                                                    unselectable: element.unselectable ?? false,
                                                    user_foreground: element.user_foreground ?? false,
                                                    keepRatio: element.keepRatio ?? true,
                                                    deletable: element.deletable ?? true,
                                                    // x: element.x,
                                                    // y: element.y,
                                                    x: (element.background || element.name === 'Background2' || isRobot) ? element.x : element.x + (newTemplateRatio.x ?? 0),
                                                    y: (element.background || element.name === 'Background2' || isRobot) ? element.y : element.y + (newTemplateRatio.y ?? 0),
                                                    name: element.name,
                                                    key: element.name,
                                                    rotation: element.rotation ?? 0,
                                                    draggable: element.draggable,
                                                    scaleX,
                                                    scaleY,
                                                    editable: element.editable ?? true,
                                                    admin: element.admin ?? 1,
                                                    changeable: element.changeable ?? 0,
                                                    transformable: element.transformable,
                                                    quickEdit: element.quickEdit,
                                                    user_transformable: element.user_transformable ?? false,
                                                    isGlobalElementUrl: element.isGlobalElementUrl ?? false,
                                                    user_changeable: element.user_changeable ?? false,
                                                    user_quickEdit: element.user_quickEdit ?? false,
                                                    layer_allocation_data: element.layer_allocation_data,
                                                    user_removeFromWorkspace: element.user_removeFromWorkspace,
                                                    user_removeFromPreview: element.user_removeFromPreview ?? false,
                                                    user_removeFromPrint: element.user_removeFromPrint ?? false,
                                                    user_adminAccessOnly: element.user_adminAccessOnly ?? false,
                                                    store_view_values: element.store_view_values ?? false,
                                                    quick_edit_labels: element.quick_edit_labels ?? false,
                                                    quick_edit_color: element.quick_edit_color ?? false,
                                                    quick_edit_font: element.quick_edit_font ?? false,
                                                    quick_edit_stroke: element.quick_edit_stroke ?? false,
                                                    quick_edit_strokeColor: element.quick_edit_strokeColor ?? false,
                                                    changePosition: element.changePosition || false,
                                                }
                                            };


                                            let stageElements = this.props.stage.children[0].children;
                                            let canvasElements = [];
                                            for (let i = 0; i < stageElements.length; i++) {
                                                let element = stageElements[i];
                                                if (element.attrs.name !== 'background' && element.constructor.name !== 'Transformer') {
                                                    console.log('element.attrs', element.attrs.user_adminAccessOnly)
                                                    switch (element.attrs.type) {
                                                        case 'square':
                                                            canvasElements.push({
                                                                ...commonProperties(element.attrs),
                                                                fill: element.attrs.fill,
                                                                width: element.attrs.width,
                                                                height: element.attrs.height,
                                                                layer_allocation_data: element.attrs.layer_allocation_data,
                                                                user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                                                            });
                                                            break;
                                                        case 'circle':
                                                            canvasElements.push({
                                                                ...commonProperties(element.attrs),
                                                                fill: element.attrs.fill,
                                                                radius: element.attrs.radius,
                                                                layer_allocation_data: element.attrs.layer_allocation_data,
                                                                user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                                                            });
                                                            break;
                                                        case 'triangle':
                                                            canvasElements.push({
                                                                ...commonProperties(element.attrs),
                                                                fill: element.attrs.fill,
                                                                radius: element.attrs.radius,
                                                                sides: element.attrs.sides,
                                                                layer_allocation_data: element.attrs.layer_allocation_data,
                                                                user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                                                            });
                                                            break;
                                                        case 'line':
                                                            canvasElements.push({
                                                                ...commonProperties(element.attrs),
                                                                tension: element.attrs.tension,
                                                                fill: element.attrs.stroke,
                                                                points: element.attrs.points,
                                                                layer_allocation_data: element.attrs.layer_allocation_data,
                                                                user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                                                            });
                                                            break;
                                                        case 'text':
                                                            canvasElements.push({
                                                                ...commonProperties(element.attrs),
                                                                user_textTransform: element.attrs.user_textTransform || {
                                                                    value: 'none',
                                                                    label: 'None'
                                                                },
                                                                data: encodeURI(element.attrs.text),
                                                                isCutoutPro: element.attrs.isCutoutPro,
                                                                isCutoutProBg: element.attrs.isCutoutProBg,
                                                                user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                                                                cartoonTypeValue: element.attrs.cartoonTypeValue,
                                                                layer_allocation_data: element.attrs.layer_allocation_data,
                                                                fill: element.attrs.fill,
                                                                fontSize: element.attrs.fontSize,
                                                                fontStyle: element.attrs.fontStyle,
                                                                fontFamily: element.attrs.fontFamily,
                                                                flipX: element.attrs.flipX || false,
                                                                flipY: element.attrs.flipY || false,
                                                                letterSpacing: element.attrs.letterSpacing || 0,
                                                                textLimit: element.attrs.textLimit,
                                                                fontStrokeWidth: element.attrs.strokeWidth,
                                                                colorFontStroke: element.attrs.stroke,
                                                                textMaxWidth: element.attrs.textMaxWidth,
                                                                align: element.attrs.align,
                                                                text: encodeURI(element.attrs.text),
                                                                shadowColor: element.attrs.shadowColor,
                                                                shadowEnabled: element.attrs.shadowEnabled,
                                                                // changePosition: element.attrs.changePosition ? element.attrs.changePosition : (element.changeLocalPosition || false),
                                                                changePosition: element.attrs.changePosition ? (element.changeLocalPosition) : element.attrs.changePosition,
                                                            });
                                                            break;
                                                        case 'icon':
                                                            canvasElements.push({
                                                                ...commonProperties(element.attrs),
                                                                width: element.attrs.width,
                                                                height: element.attrs.height,
                                                                image: element.attrs.image.src,
                                                                cropHeight: element.attrs.cropHeight || 0,
                                                                cropWidth: element.attrs.cropWidth || 0,
                                                                cropX: element.attrs.cropX || 0,
                                                                cropY: element.attrs.cropY || 0,
                                                                newBoxX: element.attrs.newBoxX || 0,
                                                                newBoxY: element.attrs.newBoxY || 0,
                                                                newBoxWidth: element.attrs.newBoxWidth || 0,
                                                                newBoxHeight: element.attrs.newBoxHeight || 0,
                                                                layer_allocation_data: element.attrs.layer_allocation_data,
                                                                user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                                                            });
                                                            break;
                                                        case 'image':
                                                            console.log(element)
                                                            canvasElements.push({
                                                                ...commonProperties(element.attrs),
                                                                bgColor: element.attrs.fill,
                                                                height: element.attrs.height * (element?.attrs?.scaleY ?? 1),
                                                                width: element.attrs.width * (element?.attrs?.scaleX ?? 1),
                                                                naturalWidth: element.attrs.naturalWidth,
                                                                naturalHeight: element.attrs.naturalHeight,
                                                                imageWithoutBg: element.attrs.imageWithoutBg,
                                                                scaleX: 1,
                                                                scaleY: 1,
                                                                smallBackground_image: element.attrs.smallBackground_image,
                                                                user_isShapeHeart: element.attrs.user_isShapeHeart,
                                                                image: element.attrs?.image?.src,
                                                                widthDiff: element.attrs.widthDiff || 0,
                                                                heightDiff: element.attrs.heightDiff || 0,
                                                                cropHeight: element.attrs.cropHeight || 0,
                                                                cropWidth: element.attrs.cropWidth || 0,
                                                                cropX: element.attrs.cropX || 0,
                                                                cropY: element.attrs.cropY || 0,
                                                                newBoxX: element.attrs.newBoxX || 0,
                                                                newBoxY: element.attrs.newBoxY || 0,
                                                                newBoxWidth: element.attrs.newBoxWidth || 0,
                                                                newBoxHeight: element.attrs.newBoxHeight || 0,
                                                                elementRepeat: element.attrs.elementRepeat || false,
                                                                elementRepeatOval: element.attrs.elementRepeatOval || false,
                                                                faceId: element.attrs.faceId,
                                                                faceCropped: element.attrs?.faceCropped,
                                                                layer_allocation_data: element.attrs.layer_allocation_data,
                                                                user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                                                            });
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }
                                            }
                                            config = JSON.stringify({
                                                widthRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio,
                                                heightRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.heightRatio,
                                                session_id: this.props.sessionId,
                                                layer_id: this.props.pages[this.props.activePage]?.baseLayer_id || this.props.layerId,
                                                design_id: this.props.designId,
                                                width: this.props.stage.attrs.width,
                                                height: this.props.stage.attrs.height,
                                                canvasElements: canvasElements?canvasElements:this.props?.pages[this.props?.activePage]?.canvasElements,
                                                product_id: this.props.productId,
                                                store_id: params.id_store,
                                                printFile: filename,
                                                previewFile: filename,
                                                borderLimit: { x, y, width, height, status: false },
                                                canvasMaskElements: JSON.stringify(this.props.canvasMaskElements)? 
                                                JSON.stringify(this.props.canvasMaskElements) : this.props.pages[this.props.activePage]?.canvasMaskElements,
                                                toolTextAllowed: this.props.toolTextAllowed,
                                                toolEmojiAllowed: this.props.toolEmojiAllowed,
                                                toolShapesAllowed: this.props.toolShapesAllowed,
                                                toolImagesAllowed: this.props.toolImagesAllowed,
                                                propertyTextStrokeAllowed: this.props.propertyTextStrokeAllowed,
                                                propertyTextStrokeColorAllowed: this.props.propertyTextStrokeColorAllowed,
                                                propertyTextShadowColorAllowed: this.props.propertyTextShadowColorAllowed,
                                                propertyTextShadowAllowed: this.props.propertyTextShadowAllowed,
                                                propertyTextColorAllowed: this.props.propertyTextColorAllowed,
                                                propertyTextShowAllowed: this.props.propertyTextShowAllowed,
                                                propertyTextFontAllowed: this.props.propertyTextFontAllowed,
                                                propertyFaceCropSizeAllowed: this.props.propertyFaceCropSizeAllowed,
                                                propertyFaceCropZoomAllowed: this.props.propertyFaceCropZoomAllowed,
                                                propertyFaceCropRotateAllowed: this.props.propertyFaceCropRotateAllowed,
                                                isMergedTemplate: this.props?.pages[this.props?.activePage]?.isMergedTemplate,
                                                newDesignTemplate:this.props?.pages[this.props?.activePage]?.newDesignTemplate,
                                                renderers:this.props?.pages[this.props?.activePage]?.renderers,
                                                pages:this.props?.pages[this.props?.activePage],
                                                configuration:this.props?.pages[this.props?.activePage]?.isMergedTemplate.configuration
                                            });
                                          {  console.log('config', JSON.parse(config))}
                                            window.onbeforeunload = null;
                                            window.parent.postMessage({
                                                datatype: "addToCart_direct",
                                                success: 1,
                                                config
                                            }, "*");
                                            if (this.props.mode === 'PRODUCTION') {
                                                window.onbeforeunload = null;
                                                window.parent.postMessage({
                                                    datatype: "addToCart_direct",
                                                    success: 1,
                                                    config
                                                }, "*");
                                            }
                                            this.props.hidePopup()
                                            if (this.props.pages[this.props.activePage].multiface_elements_admin_only === 1) {
                                                this.props.showImageChangePopup()
                                            }

                                        } else {
                                            this.setState({
                                                previewCheckMark: true
                                            });
                                        }
                                    }


                                }} theme="secondary">
                                    <Translate content="Add to cart" />
                                </Button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        isShowOrderPreviewPopup: state.tol.showOrderPreviewPopup,
        faceCropPopupData: state.tol.faceCropPopupData,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        previewUrl: state.glb.previewUrl,
        magentoUrl: state.glb.magentoUrl,
        previews: state.prv.previews,
        previewsLoadingCount: state.prv.previewsLoadingCount,
        previewShouldBeChanged: state.glb.previewShouldBeChanged,
        historyElementsCopy: state.glb.historyElementsCopy,
        historyActiveStep: state.glb.historyActiveStep,
        designerMode: state.glb.designerMode,
        canvasElements: state.glb.canvasElements,
        activeCanvasElement: state.cnv.activeCanvasElement,
        designId: state.glb.designId,
        statusText: state.glb.statusText,
        mode: state.glb.mode,
        sessionId: state.glb.sessionId,
        productId: state.glb.productId,
        layerId: state.glb.layerId,
        updateId: state.glb.updateId,
        showGrid: state.glb.showGrid,
        showGuideline: state.glb.showGuideline,
        isBorderLimitEditing: state.glb.isBorderLimitEditing,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        toolTextAllowed: state.tol.toolTextAllowed,
        toolEmojiAllowed: state.tol.toolEmojiAllowed,
        toolShapesAllowed: state.tol.toolShapesAllowed,
        toolImagesAllowed: state.tol.toolImagesAllowed,
        propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
        propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
        propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
        propertyTextShowAllowed: state.tol.propertyTextShowAllowed,
        propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
        propertyFaceCropSizeAllowed: state.tol.propertyFaceCropSizeAllowed,
        propertyFaceCropZoomAllowed: state.tol.propertyFaceCropZoomAllowed,
        propertyFaceCropRotateAllowed: state.tol.propertyFaceCropRotateAllowed,
        canvasMaskElements: state.cnvMask.canvasMaskElements,
        isItemDownloading: state.glb.isItemDownloading,
        displaytype: state.glb.displaytype,
        stepname: state.glb.stepname,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data))
        },
        showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
        onSaveLayer: (data) => dispatch(actionCreators.saveConfiguration(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
        showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
        //showBackgroundChangePopup: () => dispatch(actionCreators.showBackgroundChangePopup()),
        showBackgroundChangePopup: (data) => {
            dispatch(actionCreators.showBackgroundChangePopup(data));
        },
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        setPreviewUpdateStatus: (data) => {
            dispatch(actionCreators.setPreviewUpdateStatus(data));
        },
        showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
        onShowTextForm: () => {
            dispatch(actionCreators.showTextForm());
        },
        showTextChangePopup: () => dispatch(actionCreators.showTextChangePopup()),
        onShowDesignsForm: () => {
            dispatch(actionCreators.showDesignsForm());
        },
        onShowBackgroundsForm: () => {
            dispatch(actionCreators.showBackgroundsForm());
        },
        hideToolsMenu: () => {
            dispatch(actionCreators.hideToolsMenu());
        },
        CommonEditorStep: (data) => {
            dispatch(actionCreators.CommonEditorStep(data));
        },
    };
}
    ;

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropPopup);

